import React, { useEffect, useState, useCallback } from 'react';
import './App.css'; 
import logo from './logo.png';

const isoAlpha3 = {
  "TES": "tes",
  "USA": "usd"
  // Add other countries as needed...
};

function App() {
  const [countryCode, setCountryCode] = useState('TES');
  const [apiServer, setApiServer] = useState("https://tes.otc.run/api/v1/");
  const [scriptsLoaded, setScriptsLoaded] = useState(false);

  const updateApiServer = useCallback(() => {
    window.appConfig = window.appConfig || {};
    window.appConfig.apiserver = apiServer;

    if (typeof window.updateApiServer === 'function') {
      window.updateApiServer(apiServer);
    } else {
      console.log('updateApiServer function not found in global scope.');
    }
  }, [apiServer]);

  useEffect(() => {
    const loadScript = (src, onLoad) => {
      const script = document.createElement('script');
      script.src = src;
      script.async = true;
      script.onload = onLoad;
      document.body.appendChild(script);
      return script;
    };

    const script1 = loadScript('/script.js', () => {
      console.log('script.js loaded');
      setScriptsLoaded(true);
    });

    const script2 = loadScript('/main.js', () => {
      console.log('main.js loaded');
      setScriptsLoaded(true);
    });

    return () => {
      document.body.removeChild(script1);
      document.body.removeChild(script2);
    };
  }, []);

  useEffect(() => {
    if (scriptsLoaded) {
      updateApiServer();
    }
  }, [apiServer, scriptsLoaded, updateApiServer]);

  const handleCountryChange = (event) => {
    const selectedCountryCode = event.target.value;
    setCountryCode(selectedCountryCode);
    updateCountryCode(selectedCountryCode);
  };

  const updateCountryCode = (code) => {
    const countryCurrency = isoAlpha3[code] || "tes";
    const newApiServer = countryCurrency === "tes"
      ? "https://tes.otc.run/api/v1/"
      : `https://${countryCurrency}.otc.run/api/v1/`;

    setApiServer(newApiServer);
  };

  return (
    <div>
      <header id="header">
        <div className="container">
          <div className="pull-left">
            <div id="logo" className="pull-left navi ptr" data-nav="main" style={{ backgroundImage: `url(${logo})` }}></div>
            <select id="countrySelector" value={countryCode} onChange={handleCountryChange} className="form-control small-dropdown">
              {Object.keys(isoAlpha3).map(code => (
                <option key={code} value={code}>{code.toUpperCase()}</option>
              ))}
            </select>
          </div>
          <form className="form-inline pull-left">
            <div className="pull-left">
              <input id="searchInput" className="form-control" type="text" placeholder="id / height / tx fullhash" />
            </div>
            <div className="pull-left">
              <button id="search" type="button" className="btn btn-default">
                <span className="glyphicon glyphicon-search small"></span>
              </button>
            </div>
          </form>
          <div id="status" className="pull-right"></div>
          <div id="headerlinks" className="pull-right right-buffer-10 top-buffer-10">
            <p className="right-buffer-10">
              <span id="resources" className="ptr bluelink">Register</span>
            </p>
          </div>
        </div>
      </header>
      <main id="main" className="container">
        <div id="navigation" className="row top-buffer-10 bottom-buffer-10">
          <div className="col-md-12">
            <div className="pull-left btn-group">
              <button id="blocks" style={{ outline: 0 }} className="btn btn-default navi">Blocks</button>
              <button id="transactions" style={{ outline: 0 }} className="btn btn-default navi">Transactions</button>
              <button id="accounts" style={{ outline: 0 }} className="btn btn-default navi">Accounts</button>
              <button id="assets" style={{ outline: 0 }} className="btn btn-default navi">Assets</button>
              <button id="currencies" style={{ outline: 0 }} className="btn btn-default navi">Markets</button>
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col-md-4 pad-right-6">
            <div id="mainDet" className="detail"></div>
            <div id="altDet" className="detail"></div>
          </div>
          <div className="col-md-8 pad-left-6">
            <div id="blkLst" className="detail"></div>
            <div id="mainLst" className="detail"></div>
            <div id="altLst" className="detail"></div>
            <div id="info" className="detail"></div>
          </div>
        </div>
        <div className="row">
          <div className="col-md-12">
            <div id="fullDet" className="detail"></div>
          </div>
        </div>
      </main>
      <div id="footer">
        <div className="container">
          <div id="footerInfo" className="text-center"></div>
          <div id="debug" className="pull-left"></div>
        </div>
      </div>
    </div>
  );
}

export default App;
